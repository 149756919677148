/*
 * Usage:
 *
 * <div class="nrk-video" data-nrk-id="nrk1"></div>
 * <script src="video-embed.js"></script>
 *
 * This script creates an iframe pointing to video-embed.html for every
 * nrk-video div found. video-embed.html loads Ludo with the forwarded
 * configuration parameters. The nrk-video class is removed as the divs are
 * processed.
 *
 * Check the use of attr(...) to see supported attributes.
 *
 * The main function is exposed as window.nrk.embed.video.initialize(), and can
 * be called at any time after the script is loaded for initializing new
 * videos.
 *
 * The video-embed.html page URL can be overridden by adding
 * "#video-embed-page=..." to the parent page URL. The alternative page must be
 * on the same origin as the parent and be called video-embed.html.
 */

function embedPageOverride() {
  const p = /\bvideo-embed-page=([^&]+)/.exec(window.location.hash);
  if (p) {
    const target = decodeURIComponent(p[1]);
    const origin = `${window.location.origin}/`;
    if (target.substr(0, origin.length) === origin && /\/video-embed\.html$/.test(target)) {
      return target;
    }
  }
  return null;
}

function resolveAspectRatio(tag) {
  if (tag) {
    const match = tag.match(/^(\d+(?:\.\d+)?):(\d+(?:\.\d+)?)$/);

    if (match) {
      const width = parseFloat(match[1]);
      const height = parseFloat(match[2]);
      const ratio = height / width * 100;

      // Some rough checks, allowing aspect ratios from 2.35:1 to 9:16.
      if (width < 25 && height < 25 && ratio > 40 && ratio < 180) {
        return {
          tag,
          height: `${ratio}%`
        };
      }
    }
  }

  return {
    tag: '16:9',
    height: '56.25%'
  };
}

function attr(element, name, def = null) {
  return element.getAttribute(`data-nrk-${name}`) || def;
}

function setSize(element, height) {
  element.style.position = 'relative';
  element.style.width = '100%';
  element.style.height = 0;
  element.style.paddingTop = height;
}

function initialize() {
  const targets = document.querySelectorAll('div.nrk-video');

  Array.prototype.forEach.call(targets, (target) => {
    const aspectRatio = attr(target, 'aspect');
    const resolvedAspectRatio = resolveAspectRatio(aspectRatio);
    setSize(target, resolvedAspectRatio.height);

    target.removeAttribute('class');

    const params = {
      id: attr(target, 'id'),
      mode: attr(target, 'mode'),
      autoplay: attr(target, 'autoplay') === 'true',
      offset: parseInt(attr(target, 'offset', 0), 10),
      referrer: window.location.href,
      aspect: aspectRatio ? resolvedAspectRatio.tag : null
    };

    const queryString = Object.keys(params)
      .filter((key) => !!params[key])
      .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
      .join('&');

    const embedPage = embedPageOverride() || 'https://static.nrk.no/ludo/latest/video-embed.html';
    const url = `${embedPage}#${queryString}`;

    while (target.firstChild) {
      target.removeChild(target.firstChild);
    }

    const iframe = document.createElement('iframe');
    iframe.frameborder = '0';
    iframe.scrolling = 'no';
    iframe.style.position = 'absolute';
    iframe.style.top = 0;
    iframe.style.left = 0;
    iframe.style.width = '100%';
    iframe.style.height = '100%';
    iframe.style.border = 'none';
    iframe.src = url;
    iframe.setAttribute('allowfullscreen', '');

    const allow = ['fullscreen'];
    if (params.autoplay) {
      allow.push('autoplay');
    }
    iframe.allow = allow.join('; ');

    target.appendChild(iframe);
  });
}

// Returns embed.video.initialize, which is applied to window.nrk in the
// webpack configuration. If window.nrk already exists, the same object is
// returned.
function run() {
  const module = window.nrk || {};
  module.embed = module.embed || {};
  if (!module.embed.video) {
    module.embed.video = { initialize };
  }
  module.embed.video.initialize();
  return module;
}

module.exports = run();
